// @flow

//import ReactHtmlParser from 'react-html-parser';
// import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Breadcrumb, Card, Col, Row } from 'antd';
import React, {Fragment} from 'react';

import AntdContainer from '../components/AntdContainer';
import HtmlHead from '../components/HtmlHead';
import Img from 'gatsby-image';
import Link from '../components/Link';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {graphql} from 'gatsby';
import useContentfulImg from '../lib/hooks/useContentfulImg';

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      metaDescription
      metaKeywords
      publishDate
      updatedAt
      richBody {
        json
      }
      excerptImg {
        fluid(maxWidth: 600, maxHeight: 400) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;

const renderOptions = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: ({data}, children) => {
      const {target} = data;
      if (target.fields) {
        const {tableData} = target.fields;
        if (tableData && tableData['en-US'] && tableData['en-US'].tableData) {

          const tblInnerHtml = tableData['en-US'].tableData.map((rowArray, rowIdx) => {
            const rowInnerHtml = rowArray.map(cellVal => {
              if (!rowIdx) {
                return <th>{cellVal}</th>;
              }

              return <td>{cellVal}</td>;
            });

            return <tr>{rowInnerHtml}</tr>;
          });

          return <table className='contentful-table'>{tblInnerHtml}</table>;
        }
      }

      return <></>;
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const href = node.data && node.data.uri;
      const title = node.content && node.content[0] && node.content[0].value;
      // key is deliberately made of title and href (although even those could be duplicated)
      return (
        <Link key={href + title} to={href} rel="noopener noreferrer" target='_blank'>
          {title}
        </Link>
      )
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const imgAsset = useContentfulImg(
        node.data.target.sys.contentful_id
      );
      if (imgAsset) {
        return (
          <Img title={imgAsset.title} alt={imgAsset.description} fluid={imgAsset.fluid} />
        );
      }
      return <></>;
    }
  },
}

// {
//   // ReactHtmlParser(post.body.childMarkdownRemark.html, {transform: withHtml})
// }

const PostView = (props: Object) => {

  const {data, location} = props;
  const post = data.contentfulBlogPost;
  const formattedPublishDt = new Date(post.publishDate).toLocaleDateString();
  const formattedModifiedDt = new Date(post.updatedAt).toLocaleDateString();
  const pathname = location && location.pathname;
  const pageUrl = process.env.GATSBY_CLIENT_URL + pathname;

  return (
    <div className='section light'>
      <HtmlHead title={`${post.title} | PostSeal`} description={post.metaDescription} meta={{keywords: post.metaKeywords && post.metaKeywords.join()}}
        extraStructData={{
          "@type": "BlogPosting",
          "headline": post.title,
          "author": {
            "@type": "Organization"
          },
          "publisher": {
            "@type": "Organization"
          },
          "datePublished": post.publishDate,
          "dateModified": post.updatedAt,
          "image": post.excerptImg.fluid.src,
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": pageUrl
          }
        }}
        />
      <div className='content-wrapper'>
        <AntdContainer>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/blog">Blog</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={location.pathname}>{post.title}</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <h1 className='ant-typography top-space-4x page-title'>{post.title}</h1>
          <Row>
            <Col xs={24} md={{span: 16}}>

              <div className='long-text'>
                {
                  documentToReactComponents(post.richBody.json, renderOptions)
                }
              </div>
            </Col>
            <Col xs={24} md={{offset: 2, span: 6}}>
              <Card cover={<Img fluid={post.excerptImg.fluid} />}>
                <p className='top-space-2x'>Published on {formattedPublishDt}</p>
                <p>Last modified on {formattedModifiedDt}</p>
              </Card>

            </Col>
          </Row>
        </AntdContainer>
      </div>
    </div>
  );
};

export default PostView;
