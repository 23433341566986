// @flow

import { graphql, useStaticQuery } from "gatsby";

export default (assetId: string) => {
  const { allContentfulAsset } = useStaticQuery(
    graphql`
      query CONTENTFUL_IMAGE_QUERY {
        allContentfulAsset {
          nodes {
            contentful_id
            title
            description
            fluid(maxWidth: 700) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    `
  );

  const asset = allContentfulAsset.nodes.find(n => n.contentful_id === assetId);
  return asset;
};
 
